var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showLegend)?_c('CyNotification',{attrs:{"data-cy":"what-is-cr-legend","title":_vm.$t('whatIsCR'),"closeable":""},on:{"close":_vm.$toggle.showLegend}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('legendTextBegin'))}}),_c('router-link',{staticClass:"cy-link",attrs:{"to":{ name: 'credentials' }}},[_vm._v(" "+_vm._s(_vm.$t('Credential'))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('legendTextEnd'))}})],1):_vm._e(),_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"id":"cy-config-repositories-table","fetch-available":[{ keyPath: 'configRepositories' }, { keyPath: 'credentials' }],"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"bulk":_vm.hasBulkModeEnabled,"searchable-fields":_vm.$static.searchableFields,"key-field":"canonical"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(!_vm.showLegend)?_c('a',{staticClass:"cy-link show-legend mr-4",attrs:{"data-cy":"what-is-cr-button"},on:{"click":_vm.$toggle.showLegend}},[_vm._v(" "+_vm._s(_vm.$t('whatIsCR'))+" ")]):_vm._e(),(!_vm._.isEmpty(selected))?_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteConfigReposBtn', selected.length, { nb: selected.length }))+" ")]):_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateConfigRepository'),expression:"'CreateConfigRepository'"}],attrs:{"icon":"add","data-cy":"create-config-repo-button","to":{ name: 'newConfigRepository' }},nativeOn:{"click":function($event){return _vm.$gtm.trackEvent(_vm.$static.gtmProjectsEvents.configRepositoriesAddConfigRepository)}}},[_vm._v(" "+_vm._s(_vm.$t('addConfigRepository'))+" ")]),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmDeleteTitle', _vm.toDelete.length, { nb: _vm.toDelete.length }),"action-btn-func":_vm.onDelete,"cancel-btn-func":function () { return _vm.$toggle.showDeleteModal(false); },"action-btn-text":_vm.$tc('deleteConfigReposBtn', _vm.toDelete.length, { nb: _vm.toDelete.length }),"loading":_vm.isDeleting,"small":"","modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length))}})]),_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
var url = ref.url;
return _c('li',{key:canonical},[_c('h3',[_vm._v(_vm._s(name))]),_c('p',{staticClass:"url"},[_vm._v(" "+_vm._s(url)+" ")])])}),0),_c('p',[_vm._v(_vm._s(_vm.$t('confirmDeleteRepository')))])]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[(item.default)?_c('v-icon',[_vm._v(" check ")]):_vm._e()],1),_c('td',[(item.credential_canonical)?_c('CyButton',{key:item.canonical,staticClass:"credential-link",attrs:{"theme":"grey","variant":"tertiary","sm":"","disabled":!_vm.canGoToCredential(item),"readonly":!_vm.canGoToCredential(item),"to":{
            name: 'credential',
            params: {
              credentialCanonical: item.credential_canonical,
              backRouteTo: 'configRepositories',
            },
          }},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.getCredential(item.credential_canonical).type)?_c('CyIconCredential',{attrs:{"type":_vm.getCredential(item.credential_canonical).type}}):_vm._e(),_c('span',{staticClass:"credential-link__name"},[_vm._v(" "+_vm._s(_vm.getCredential(item.credential_canonical).name)+" ")])],1):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.branch))]),_c('td',[_c('div',[_c('CyCopyBtn',{staticClass:"mr-2",attrs:{"copy-value":item.url,"small":""},on:{"click":function($event){$event.preventDefault();}}}),_vm._v(" "+_vm._s(item.url)+" ")],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }